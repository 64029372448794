import axios from "axios";
import { RequestMethods } from "../../shared/RequestMethods";
import { premiumServiceCreate } from "../../Components/constants";

export const createPremiumService = async (
  item: premiumServiceCreate
): Promise<{
  order_id: string;
  currency: string;
  amount: number;
  premiumServiceId: string;
}> => {
  const extoken: string = localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

  let config = {
    method: RequestMethods.POST,
    url: `${process.env.REACT_APP_BASE_API}/premium-services`,
    headers: {
      Authorization: `Bearer ${extoken}`,
      "Content-Type": "application/json",
    },
    data: item,
  };

  const response = await axios(config);
  const { order_id, currency, amount, premiumServiceId } = response.data;

  return { order_id, currency, amount, premiumServiceId };
};

import React, { useState } from "react";

import commercialization_strategy from "../../assests/images/premium-services/commercialization-strategy.svg";
import displayer_premium_services from "../../assests/images/premium-services/displayer-premium-services.svg";
import identify_pilot_project from "../../assests/images/premium-services/identifying-pilot-project.svg";
import local_business_model_development from "../../assests/images/premium-services/local-business-model-development.svg";
import market_entry_strategy from "../../assests/images/premium-services/market-entry-strategy.svg";
import partner_customer_investor_connects_1 from "../../assests/images/premium-services/partner-customer-investor-connects-1.svg";
import partner_customer_investor_connects_2 from "../../assests/images/premium-services/partner-customer-investor-connects-2.svg";
import partner_customer_investor_connects from "../../assests/images/premium-services/partner-customer-investor-connects.svg";
import { useNavigate } from "react-router-dom";
import { premiumServiceCreate, premiumServicesDetails } from "../constants";
import { createPremiumService } from "../../store/actioncreators/premiumservicesactions";
import gbi_home_logo from "../../assests/home/logo_home.svg";
import { useSelector } from "react-redux";
import SuccessModal from "./SuccessModal";

const DisplayerPremiumServices = ({
  handleLoginModal,
}: {
  handleLoginModal: () => void;
}) => {
  const navigate = useNavigate();

  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [state, setState] = useState("LOADING");
  const [message, setMessage] = useState("");

  const user: USER = useSelector((state: STATE) => state.USER.USER);

  const [value, setValue] = useState<number>(5);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.target.value));
  };

  const paymentHandler = async (
    amount: number,
    currency: string,
    order_id: string,
    premiumServiceId: string
  ) => {
    let options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount,
      currency,
      name: "GTI Market Access and Innovation Services for Technology Scouters",
      description:
        "GTI Market Access and Innovation Services for Technology Scouters",
      image: gbi_home_logo,
      order_id,
      callback_url: `${process.env.REACT_APP_BASE_API}/payments/validate`,
      prefill: {
        name: user?.user?.name,
        email: user?.user?.email,
      },
      notes: {
        premiumServiceId,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new (window as any).Razorpay(options);

    rzp.on("payment.failed", function (response: any) {
      alert("Payment Failed. Please Retry again.");
      navigate("/featured/failed");
    });

    rzp.open();
  };

  const handleSuccessModal = (
    isOpen: boolean,
    state: string,
    message: string
  ) => {
    setSuccessModal(isOpen);
    setState(state);
    setMessage(message);
  };

  const handleCreate = async (value: premiumServiceCreate) => {
    try {
      if (user?.admin !== -1) {
        handleSuccessModal(true, "LOADING", "");

        console.log({ value });

        const currency = "USD";

        const { title, description, timeline, reportLength, amount } = value;

        const data = {
          title,
          description,
          timeline,
          reportLength,
          amount,
          currency,
        };

        const { order_id, premiumServiceId } = await createPremiumService(data);

        handleSuccessModal(false, "LOADING", "");

        paymentHandler(amount, currency, order_id, premiumServiceId);
      } else {
        handleLoginModal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex flex-col relative px-2 py-2 lg:px-10 lg:py-10 gap-10 items-center w-full">
      <div className="flex gap-10">
        <div className="flex flex-col gap-10">
          <h1 className="text-GTI-BLUE-default text-2xl lg:text-4xl font-semibold">
            GTI Market Access and Innovation Services for Technology Displayers
          </h1>
          <img
            className="block lg:hidden w-3/4 h-3/4 m-auto"
            src={displayer_premium_services}
            alt="Displayer Market Access and Innovation Services"
          />
          <p className="text-sm lg:text-xl font-light">
            GTI Market Access and Innovation Services for Technology Displayers
            is aimed at helping the Technology displayer companies,
            entrepreneurs, MSMEs, startups or R&D institutions in a
            comprehensive manner to deploy and validate their technology in a
            new market. Market research can identify how customers and potential
            customers might view your business and identify gaps in customer
            expectations. Having good market intelligence helps to minimise
            risks when making critical business decisions. We also offer deep
            market insights and assistance in finding the right partnerships in
            your target market.
          </p>
        </div>
        <img
          className="hidden lg:block"
          src={displayer_premium_services}
          alt="Displayer Market Access and Innovation Services"
        />
      </div>
      <div className="flex flex-col justify-center gap-10 bg-blue-100 p-5 rounded-2xl w-full">
        <h1 className="text-GTI-BLUE-default text-lg lg:text-2xl font-semibold text-center">
          Market insights and Technology Validation report Customised for your
          technology and target market
        </h1>
        <div className="grid grid-rows-3 gap-10 lg:gap-0 lg:grid-rows-1 lg:grid-cols-3">
          <div className="flex flex-col bg-white mx-3 lg:mx-10 rounded-2xl">
            <div className="flex flex-col justify-center items-center p-2 gap-2">
              <h3 className="text-GTI-BLUE-default font-semibold">
                {premiumServicesDetails.marketInsightReport.title}
              </h3>
              <p>{premiumServicesDetails.marketInsightReport.description}</p>
              <h3 className="text-GTI-BLUE-default font-semibold self-start">
                Timeline: 15 Days
              </h3>
              <h3 className="text-GTI-BLUE-default font-semibold self-start">
                Report length: 6-8 pages
              </h3>
            </div>
            <div className="flex-grow"></div>
            <button
              className="text-white bg-GTI-BLUE-default w-full p-2 rounded-bl-2xl rounded-br-2xl"
              onClick={() =>
                handleCreate({
                  title: premiumServicesDetails.marketInsightReport.title,
                  description:
                    premiumServicesDetails.marketInsightReport.description,
                  timeline: "15",
                  reportLength: "6-8",
                  amount: 1000,
                })
              }
            >
              Fees $1,000
            </button>
          </div>
          <div className="flex flex-col bg-white mx-3 lg:mx-10 rounded-2xl">
            <div className="flex flex-col justify-center items-center p-2 gap-2">
              <h3 className="text-GTI-BLUE-default font-semibold">
                {
                  premiumServicesDetails
                    .detailedMarketInsightAndTechnologyValidationeReport.title
                }
              </h3>
              <p>
                {
                  premiumServicesDetails
                    .detailedMarketInsightAndTechnologyValidationeReport
                    .description
                }
              </p>
              <h3 className="text-GTI-BLUE-default font-semibold self-start">
                Timeline: 1 month
              </h3>
              <h3 className="text-GTI-BLUE-default font-semibold self-start">
                Report length: 15-20 pages
              </h3>
            </div>
            <div className="flex-grow"></div>
            <button
              className="text-white bg-GTI-BLUE-default w-full p-2 rounded-bl-2xl rounded-br-2xl"
              onClick={() =>
                handleCreate({
                  title:
                    premiumServicesDetails
                      .detailedMarketInsightAndTechnologyValidationeReport
                      .title,
                  description:
                    premiumServicesDetails
                      .detailedMarketInsightAndTechnologyValidationeReport
                      .description,
                  timeline: "30",
                  reportLength: "15-20",
                  amount: 3000,
                })
              }
            >
              Fees $3,000
            </button>
          </div>
          <div className="flex flex-col bg-white mx-3 lg:mx-10 rounded-2xl">
            <div className="flex flex-col justify-center items-center p-2 gap-2">
              <h3 className="text-GTI-BLUE-default font-semibold">
                {
                  premiumServicesDetails.identificationAndFacilitationOfMeetings
                    .title
                }
              </h3>
              <p>
                {
                  premiumServicesDetails.identificationAndFacilitationOfMeetings
                    .description
                }
              </p>
              <input
                type="range"
                min="5"
                max="10"
                step="1"
                value={value}
                onChange={handleChange}
                className="w-full mt-10 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
              <div className="flex justify-between w-full mt-2 text-base text-gray-700">
                <span className="text-GTI-BLUE-default font-bold">{value}</span>
                <span>10</span>
              </div>
              <p>*Curated connections and meetings</p>
            </div>
            <div className="flex-grow"></div>
            {value === 5 ? (
              <button
                className="text-white bg-GTI-BLUE-default w-full p-2 rounded-bl-2xl rounded-br-2xl"
                onClick={() =>
                  handleCreate({
                    title:
                      premiumServicesDetails
                        .identificationAndFacilitationOfMeetings.title,
                    description:
                      premiumServicesDetails
                        .identificationAndFacilitationOfMeetings.description,
                    amount: value * 1000,
                  })
                }
              >
                Pay ${value},000
              </button>
            ) : (
              <a
                href="mailto:info@globaltechinterface.com"
                className="text-white bg-GTI-BLUE-default p-2 rounded-bl-2xl rounded-br-2xl text-center block"
              >
                Send Email
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-10">
        <div className="flex flex-col gap-10">
          <h1 className="text-GTI-BLUE-default text-2xl lg:text-3xl font-semibold">
            Partner/Customer/Investor Connects
          </h1>
          <img
            className="block lg:hidden w-1/2 h-1/2 m-auto"
            src={partner_customer_investor_connects}
            alt="Partner Customer Investor Connects"
          />
          <p className="text-sm lg:text-lg font-light">
            Finding the right partners is one of the biggest challenges
            companies or startups face when entering new markets. We know that
            for many businesses, particularly SMEs, not knowing how to enter a
            global market successfully is also the number one reason
            international growth opportunities are left unexplored. So as part
            of our services, we offer technology displayers customised solutions
            and support for finding the right partners, customers and investors
            through the following services.
          </p>
        </div>
        <img
          className="hidden lg:block"
          src={partner_customer_investor_connects}
          alt="Partner Customer Investor Connects"
        />
      </div>
      <div className="flex flex-col justify-center gap-10 p-5 rounded-2xl w-full bg-blue-100">
        <div className="grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 gap-10">
          <div className="flex flex-col gap-5 bg-white p-5 justify-start items-start rounded-2xl">
            <img
              src={partner_customer_investor_connects_1}
              className="self-center"
              alt="Identification and facilitation of meetings with potential partners, customers or investors"
            />
            <div className="flex gap-5 justify-center items-center">
              <h4 className="bg-blue-100 p-2 rounded-xl text-xl">01</h4>
              <p>
                Identification and facilitation of meetings with potential
                partners, customers or investors.
              </p>
            </div>
            <p className="pl-2">
              In case of higher requirements please reach out to us directly.
              GTI will help you find the right partner by understanding your
              requirements and curating one-on-one connections and business
              meetings with potential partners, customers or investors.
            </p>
            <div className="flex-grow"></div>
            <div className="flex justify-center items-center w-full">
              <a
                href="mailto:info@globaltechinterface.com"
                className="text-white w-1/2 bg-GTI-BLUE-default p-2 rounded-2xl text-center block"
              >
                Send Email
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-5 bg-white p-5 justify-start items-start rounded-2xl">
            <img
              src={partner_customer_investor_connects_2}
              className="self-center"
              alt="Local Representation and/or Follow up support with deal making"
            />
            <div className="flex gap-5 justify-center items-center">
              <h4 className="bg-blue-100 p-2 rounded-xl text-xl">02</h4>
              <p>
                Local Representation and/or Follow up support with deal making
              </p>
            </div>
            <p className="pl-2">
              GTI will act as your local representative and provide support and
              assistance required for the deal making with potential partners,
              customers or investors.
            </p>
            <ol className="list-disc font-bold pl-5">
              <li>Daily man day cost - $ 300 / day</li>
              <li>Monthly cost - $4,000</li>
            </ol>
            <div className="flex-grow"></div>
            <div className="flex justify-center items-center w-full">
              <a
                href="mailto:info@globaltechinterface.com"
                className="text-white w-1/2 bg-GTI-BLUE-default p-2 rounded-2xl text-center block"
              >
                Send Email
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center gap-10 p-5 rounded-2xl w-full">
        <h1 className="text-GTI-BLUE-default text-xl lg:text-3xl font-semibold text-center">
          Additional customized services for market access
        </h1>
        <div className="grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 gap-5 lg:gap-20">
          <div className="flex flex-col mx-3 lg:mx-10 rounded-3xl bg-blue-50 max-w-[500px] max-h-1/2">
            <img
              src={identify_pilot_project}
              className="max-w-1/2 max-h-[400px]"
              alt="Identify Pilot Project"
            />
            <div className="flex-grow"></div>
            <div className="flex gap-5 justify-center items-center m-2">
              <h5 className="bg-white p-2 font-semibold rounded-xl">01</h5>
              <p className="font-semibold">Identifying pilot project</p>
            </div>
          </div>
          <div className="flex flex-col mx-3 lg:mx-10 rounded-2xl bg-blue-50 max-w-[500px] max-h-1/2">
            <img
              src={local_business_model_development}
              className="max-w-1/2 max-h-[400px]"
              alt="Identify Pilot Project"
            />
            <div className="flex-grow"></div>
            <div className="flex gap-5 justify-center items-center m-2">
              <h5 className="bg-white p-2 font-semibold rounded-xl">02</h5>
              <p className="font-semibold">Local business model development</p>
            </div>
          </div>
          <div className="flex flex-col mx-3 lg:mx-10 rounded-2xl bg-blue-50 max-w-[500px] max-h-1/2">
            <img
              src={commercialization_strategy}
              className="max-w-1/2 max-h-[400px]"
              alt="Identify Pilot Project"
            />
            <div className="flex-grow"></div>
            <div className="flex gap-5 justify-center items-center m-2">
              <h5 className="bg-white p-2 font-semibold rounded-xl">03</h5>
              <p className="font-semibold">Commercialization strategy</p>
            </div>
          </div>
          <div className="flex flex-col mx-3 lg:mx-10 rounded-2xl bg-blue-50 max-w-[500px] max-h-1/2">
            <img
              src={market_entry_strategy}
              className="max-w-1/2 max-h-[400px]"
              alt="Identify Pilot Project"
            />
            <div className="flex-grow"></div>
            <div className="flex gap-5 justify-center items-center m-2">
              <h5 className="bg-white p-2 font-semibold rounded-xl">04</h5>
              <p className="font-semibold">Market Entry Strategy</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center w-full">
        <a
          href="mailto:info@globaltechinterface.com"
          className="text-white lg:w-1/2 bg-GTI-BLUE-default p-2 rounded-2xl text-center block"
        >
          Send Email for customised services
        </a>
      </div>
      {successModal && (
        <SuccessModal
          state={state}
          message={message}
          show={successModal}
          toggle={handleSuccessModal}
        />
      )}
    </div>
  );
};

export default DisplayerPremiumServices;

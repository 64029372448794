import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import * as ROUTE from "../Constants/routes";
import {
  HEADER_ARTICLES,
  HEADER_PUBLICATIONS,
  HEADER_EVENTS,
  HEADER_HELPDESK,
  HEADER_INNOVATIONCALL,
  HEADER_OPPORTUNITES,
  HEADER_TECHNOLOGIES,
  HEADER_YOUR_TECHNOLOGIES,
  HELPDESK,
  HOME,
  INNOVATION,
  OPPORTUNITY,
  TECHNOLOGY,
  YOUR_HEADER_OPPORTUNITES,
  YOUR_OPPORTUNITY,
  YOUR_TECHNOLOGY,
  HEADER_NEWS,
  HEADER_PREMIUM_SERVICES_DISPLAYER,
  HEADER_PREMIUM_SERVICES_SCOUTER,
} from "../constants";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useSelector } from "react-redux";
import { PROFILE_TYPES } from "../../App";

interface IHMid {
  handleShow?: any;
  show?: boolean;
}

const H_Mid: React.FC<IHMid> = ({ handleShow, show }) => {
  const location = useLocation();
  const user = useSelector((state: STATE) => state.USER.USER);
  const [mediaDrop, setMediaDrop] = useState(false);
  const [productDrop, setProductDrop] = useState(false);
  const [oppDrop, setOppDrop] = useState(false);
  const [aboutDrop, setAboutDrop] = useState(false);
  const [premiumServicesDrop, setPremiumServicesDrop] = useState(false);
  const media_ref = useDetectClickOutside({
    onTriggered: () => setMediaDrop(false),
  });
  const products_ref = useDetectClickOutside({
    onTriggered: () => setProductDrop(false),
  });
  const opp_ref = useDetectClickOutside({
    onTriggered: () => setOppDrop(false),
  });
  const about_ref = useDetectClickOutside({
    onTriggered: () => setAboutDrop(false),
  });
  const premium_services_ref = useDetectClickOutside({
    onTriggered: () => setPremiumServicesDrop(false),
  });

  const currentUser: USER = useSelector((state: STATE) => state.USER.USER);

  return (
    <div
      className={` lg:flex justify-center items-center mx-auto ${
        show ? "lg:flex block" : "hidden lg:flex"
      }`}
    >
      <ul className="lg:flex items-center lg:space-y-0 space-y-4 space-x-0 xl:space-x-3 mx-0 md:mx-2 py-1 mt-2">
        {currentUser.admin === -1 ? (
          <li>
            <Link
              className={
                "item " +
                (location.pathname === HOME
                  ? "text-black hover:font-semibold md:hover:text-GTI-BLUE-default"
                  : "text-black lg:text-white hover:font-semibold md:hover:bg-blue-900")
              }
              to={TECHNOLOGY}
              onClick={() => {
                handleShow();
              }}
            >
              {HEADER_TECHNOLOGIES}
            </Link>
          </li>
        ) : (
          <li ref={products_ref}>
            <button
              id="product_drop"
              onClick={() => {
                setProductDrop(!productDrop);
                // handleShow();
              }}
              data-dropdown-toggle="dropdown"
              className="inline-flex items-center"
              type="button"
            >
              <Link
                className={`whitespace-nowrap ${
                  location.pathname === HOME
                    ? "text-black hover:font-semibold md:hover:text-GTI_BLUE-default"
                    : "text-black lg:text-white hover:font-semibold md:hover:bg-blue-900"
                } text-sm xl:text-xl ml-2`}
                to={TECHNOLOGY}
                onClick={() => {
                  handleShow();
                }}
              >
                {HEADER_TECHNOLOGIES}
              </Link>
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                fill="none"
                stroke={`${location.pathname === HOME ? "#000080" : "white"}`}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>
            <div
              id="product_dropdown"
              className={
                "relative z-10 translate-y-4   " + (productDrop ? "" : "hidden")
              }
              data-popper-reference-hidden=""
              data-popper-escaped=""
              data-popper-placement="bottom"
            >
              <ul
                className="absolute text-center flex flex-col bg-slate-100 shadow-md shadow-GTI-BLUE-default w-38 rounded"
                aria-labelledby="dropdownDefault"
                onMouseLeave={() => {
                  setProductDrop(false);
                  handleShow();
                }}
              >
                <li
                  onClick={() => {
                    setProductDrop(!productDrop);
                    handleShow();
                  }}
                >
                  <Link
                    to={TECHNOLOGY}
                    onClick={() => {
                      handleShow();
                    }}
                    className="whitespace-nowrap text-start font-roboto block py-2 px-4 rounded  text-GTI-BLUE-default  hover:text-slate-500 "
                  >
                    {HEADER_TECHNOLOGIES}
                  </Link>
                </li>
                {!!user?.userType ||
                user?.userType !== PROFILE_TYPES.GENERAL_SUBSCRIBER ? (
                  <li
                    onClick={() => {
                      setProductDrop(!productDrop);
                      handleShow();
                    }}
                  >
                    <Link
                      to={YOUR_TECHNOLOGY}
                      onClick={() => {
                        handleShow();
                      }}
                      className="whitespace-nowrap text-start font-roboto block py-2 px-4 rounded  text-GTI-BLUE-default  hover:text-slate-500 "
                    >
                      {HEADER_YOUR_TECHNOLOGIES}
                    </Link>
                  </li>
                ) : null}
              </ul>
            </div>
          </li>
        )}
        {currentUser.admin === -1 ? (
          <li>
            <Link
              className={
                "item whitespace-nowrap " +
                (location.pathname === HOME
                  ? "text-black hover:font-semibold hover:text-GTI-BLUE-default"
                  : "text-black lg:text-white hover:font-semibold md:hover:bg-blue-900")
              }
              onClick={() => {
                handleShow();
              }}
              to={OPPORTUNITY}
            >
              {HEADER_OPPORTUNITES}
            </Link>
          </li>
        ) : (
          <li ref={opp_ref}>
            <button
              id="opp_drop"
              onClick={() => {
                setOppDrop(!oppDrop);
                // handleShow();
              }}
              data-dropdown-toggle="dropdown"
              className="inline-flex items-center"
              type="button"
            >
              <Link
                className={`whitespace-nowrap ${
                  location.pathname === HOME
                    ? "text-black hover:text-GTI_BLUE-default"
                    : "text-black lg:text-white hover:font-semibold md:hover:bg-blue-900"
                } text-sm xl:text-xl ml-2`}
                to={OPPORTUNITY}
              >
                {HEADER_OPPORTUNITES}
              </Link>
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                fill="none"
                stroke={`${location.pathname === HOME ? "#000080" : "white"}`}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>
            <div
              id="opp_dropdown"
              className={
                "relative z-10 translate-y-4   " + (oppDrop ? "" : "hidden")
              }
              data-popper-reference-hidden=""
              data-popper-escaped=""
              data-popper-placement="bottom"
            >
              <ul
                className="absolute text-center flex flex-col bg-slate-100 shadow-md shadow-GTI-BLUE-default w-38 rounded"
                aria-labelledby="dropdownDefault"
                onMouseLeave={() => {
                  setOppDrop(false);
                  handleShow();
                }}
              >
                <li
                  onClick={() => {
                    setOppDrop(!oppDrop);
                    handleShow();
                  }}
                >
                  <Link
                    to={OPPORTUNITY}
                    className="font-roboto text-start block py-2 px-4 rounded  text-GTI-BLUE-default  hover:text-slate-500 "
                    onClick={() => {
                      handleShow();
                    }}
                  >
                    {HEADER_OPPORTUNITES}
                  </Link>
                </li>
                {!!user?.userType ||
                user?.userType !== PROFILE_TYPES.GENERAL_SUBSCRIBER ? (
                  <li
                    onClick={() => {
                      setOppDrop(!oppDrop);
                      handleShow();
                    }}
                  >
                    <Link
                      to={YOUR_OPPORTUNITY}
                      className="font-roboto text-start block py-2 px-4 rounded  text-GTI-BLUE-default  hover:text-slate-500 "
                      onClick={() => {
                        handleShow();
                      }}
                    >
                      {YOUR_HEADER_OPPORTUNITES}
                    </Link>
                  </li>
                ) : null}
              </ul>
            </div>
          </li>
        )}
        <li>
          <Link
            className={
              "item whitespace-nowrap " +
              (location.pathname === HOME
                ? "text-black hover:font-semibold hover:text-GTI-BLUE-default"
                : "text-black lg:text-white hover:font-semibold md:hover:bg-blue-900")
            }
            onClick={() => {
              handleShow();
            }}
            to={INNOVATION}
          >
            {HEADER_INNOVATIONCALL}
          </Link>
        </li>
        <li>
          <Link
            className={
              "item " +
              (location.pathname === HOME
                ? "text-black hover:font-semibold  hover:text-GTI-BLUE-default"
                : "text-black lg:text-white hover:font-semibold md:hover:bg-blue-900")
            }
            onClick={() => {
              handleShow();
            }}
            to={HELPDESK}
          >
            {HEADER_HELPDESK}
          </Link>
        </li>
        <li ref={media_ref}>
          <button
            id="media_drop"
            onClick={() => {
              setMediaDrop(!mediaDrop);
            }}
            data-dropdown-toggle="dropdown"
            className="inline-flex items-center px-2 md:px-0"
            type="button"
          >
            <Link
              className={
                "text-sm xl:text-xl duration-200 rounded-lg  md:px-1" +
                (location.pathname === HOME
                  ? "text-black hover:font-semibold  hover:text-GTI-BLUE-default"
                  : "text-black lg:text-white hover:font-semibold md:hover:bg-blue-900")
              }
              to="/articles"
            >
              Media
            </Link>
            <svg
              className="w-4 h-4 hidden md:block"
              aria-hidden="true"
              fill="none"
              stroke={location.pathname === HOME ? "black" : "white"}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>
          <div
            id="media_dropdown"
            className={
              "relative z-10 translate-y-4   " + (mediaDrop ? "" : "hidden")
            }
            data-popper-reference-hidden=""
            data-popper-escaped=""
            data-popper-placement="bottom"
          >
            <ul
              className="absolute text-center flex flex-col bg-white shadow-md shadow-GTI-BLUE-default w-32 rounded"
              aria-labelledby="dropdownDefault"
              onMouseLeave={() => {
                setMediaDrop(false);
              }}
            >
              <li
                onClick={() => {
                  setMediaDrop(!mediaDrop);
                  handleShow();
                }}
              >
                <Link
                  to={ROUTE.ARTICLE}
                  onClick={() => {
                    handleShow();
                  }}
                  className="font-roboto text-start block py-2 px-4 rounded  text-GTI-BLUE-default  hover:text-slate-500 "
                >
                  {HEADER_ARTICLES}
                </Link>
              </li>
              <li
                onClick={() => {
                  setMediaDrop(!mediaDrop);
                  handleShow();
                }}
              >
                <Link
                  to={ROUTE.PUBLICATIONS}
                  onClick={() => {
                    handleShow();
                  }}
                  className="font-roboto text-start block py-2 px-4 rounded  text-GTI-BLUE-default  hover:text-slate-500 "
                >
                  {HEADER_PUBLICATIONS}
                </Link>
              </li>
              <li
                onClick={() => {
                  setMediaDrop(!mediaDrop);
                  handleShow();
                }}
              >
                <Link
                  to={ROUTE.EVENTS}
                  onClick={() => {
                    handleShow();
                  }}
                  className="font-roboto text-start block py-2 px-4 rounded  text-GTI-BLUE-default  hover:text-slate-500 "
                >
                  {HEADER_EVENTS}
                </Link>
              </li>
              <li
                onClick={() => {
                  setMediaDrop(!mediaDrop);
                }}
              >
                <Link
                  to={ROUTE.NEWS}
                  className="font-roboto text-start block py-2 px-4 rounded  text-GTI-BLUE-default  hover:text-slate-500 "
                >
                  {HEADER_NEWS}
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li ref={premium_services_ref}>
          <button
            id="premium_services_drop"
            onClick={() => {
              setPremiumServicesDrop(!premiumServicesDrop);
            }}
            data-dropdown-toggle="dropdown"
            className="inline-flex items-center px-2 md:px-0"
            type="button"
          >
            <Link
              className={
                "text-sm xl:text-xl duration-200 rounded-lg  md:px-1" +
                (location.pathname === HOME
                  ? "text-black hover:font-semibold  hover:text-GTI-BLUE-default"
                  : "text-black lg:text-white hover:font-semibold md:hover:bg-blue-900")
              }
              to="/"
            >
              GTI Services
            </Link>
            <svg
              className="w-4 h-4 hidden md:block"
              aria-hidden="true"
              fill="none"
              stroke={location.pathname === HOME ? "black" : "white"}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>
          <div
            id="premium_services_drop"
            className={
              "relative z-10 translate-y-4   " +
              (premiumServicesDrop ? "" : "hidden")
            }
            data-popper-reference-hidden=""
            data-popper-escaped=""
            data-popper-placement="bottom"
          >
            <ul
              className="absolute text-center flex flex-col bg-white shadow-md shadow-GTI-BLUE-default w-44 rounded"
              aria-labelledby="dropdownDefault"
              onMouseLeave={() => {
                setPremiumServicesDrop(false);
              }}
            >
              <li
                onClick={() => {
                  setPremiumServicesDrop(!premiumServicesDrop);
                  handleShow();
                }}
              >
                <Link
                  to={ROUTE.DISPLAYER_PREMIUM_SERVICES}
                  onClick={() => {
                    handleShow();
                  }}
                  className="font-roboto text-start block py-2 px-4 rounded  text-GTI-BLUE-default  hover:text-slate-500 "
                >
                  {HEADER_PREMIUM_SERVICES_DISPLAYER}
                </Link>
              </li>
              <li
                onClick={() => {
                  setPremiumServicesDrop(!premiumServicesDrop);
                  handleShow();
                }}
              >
                <Link
                  to={ROUTE.SCOUTER_PREMIUM_SERVICES}
                  onClick={() => {
                    handleShow();
                  }}
                  className="font-roboto text-start block py-2 px-4 rounded  text-GTI-BLUE-default  hover:text-slate-500 "
                >
                  {HEADER_PREMIUM_SERVICES_SCOUTER}
                </Link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default H_Mid;

import React, { useState } from "react";

import scouter_premium_services from "../../assests/images/premium-services/scouter-premium-services.svg";
import { premiumServiceCreate, premiumServicesDetails } from "../constants";
import { useNavigate } from "react-router-dom";
import { createPremiumService } from "../../store/actioncreators/premiumservicesactions";
import gbi_home_logo from "../../assests/home/logo_home.svg";
import { useSelector } from "react-redux";
import SuccessModal from "./SuccessModal";

const ScouterPremiumServices = ({
  handleLoginModal,
}: {
  handleLoginModal: () => void;
}) => {
  const navigate = useNavigate();

  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [state, setState] = useState("LOADING");
  const [message, setMessage] = useState("");

  const user: USER = useSelector((state: STATE) => state.USER.USER);

  const paymentHandler = async (
    amount: number,
    currency: string,
    order_id: string,
    premiumServiceId: string
  ) => {
    let options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount,
      currency,
      name: "GTI Market Access and Innovation Services for Technology Scouters",
      description:
        "GTI Market Access and Innovation Services for Technology Scouters",
      image: gbi_home_logo,
      order_id,
      callback_url: `${process.env.REACT_APP_BASE_API}/payments/validate`,
      prefill: {
        name: user?.user?.name,
        email: user?.user?.email,
      },
      notes: {
        premiumServiceId,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new (window as any).Razorpay(options);

    rzp.on("payment.failed", function (response: any) {
      alert("Payment Failed. Please Retry again.");
      navigate("/featured/failed");
    });

    rzp.open();
  };

  const handleSuccessModal = (
    isOpen: boolean,
    state: string,
    message: string
  ) => {
    setSuccessModal(isOpen);
    setState(state);
    setMessage(message);
  };

  const handleCreate = async (value: premiumServiceCreate) => {
    try {
      if (user?.admin !== -1) {
        handleSuccessModal(true, "LOADING", "");

        const currency = "USD";

        const { title, description, timeline, reportLength, amount } = value;

        const data = {
          title,
          description,
          timeline,
          reportLength,
          amount,
          currency,
        };

        const { order_id, premiumServiceId } = await createPremiumService(data);

        handleSuccessModal(false, "LOADING", "");

        paymentHandler(amount, currency, order_id, premiumServiceId);
      } else {
        handleLoginModal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex flex-col relative px-2 py-2 lg:px-10 lg:py-10 gap-10 items-center w-full">
      <div className="flex gap-10">
        <div className="flex flex-col gap-10">
          <h1 className="text-GTI-BLUE-default text-2xl lg:text-4xl font-semibold">
            GTI Market Access and Innovation Services for Technology Scouters
          </h1>
          <img
            className="block lg:hidden w-3/4 h-3/4 m-auto"
            src={scouter_premium_services}
            alt="Scouter GTI Services"
          />
          <p className="text-sm lg:text-xl font-light">
            GTI Market Access and Innovation Services are for Technology
            Scouters looking for customised assistance in finding technology
            partners for collaborations or investments. Technology scouting
            processes help to search the market for external disruptive business
            opportunities. Our technology scouting platform that provides
            customised services can be pivotal for leveraging an effective
            innovation scouting process. GTI offers services such as curated
            technology scouting & open innovation challenges, in-depth technical
            analysis, workshops, pitch sessions and assistance with technology
            deployment.
          </p>
        </div>
        <img
          className="hidden lg:block"
          src={scouter_premium_services}
          alt="Scouter GTI Services"
        />
      </div>
      <div className="flex flex-col justify-center gap-10 bg-blue-100 p-5 rounded-2xl w-full">
        <h1 className="text-GTI-BLUE-default text-lg lg:text-2xl font-semibold text-center">
          Technology Scouting / Open Innovation challenges
        </h1>
        <p className="text-sm lg:text-lg">
          By strategically identifying partnership and opportunities with
          technologies available in the market, companies can ensure their
          offerings are responsive to changing customer demands. Technology
          Scouters can scout for partners, innovation, intellectual property
          (IP), technologies and/or solutions to improve your existing
          businesses or productivity.
        </p>
        <div className="grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 gap-10">
          <div className="flex flex-col items-center bg-white gap-2 rounded-2xl">
            <div className="flex flex-col justify-center items-center p-2 gap-2">
              <h2 className="text-GTI-BLUE-default text-xl font-semibold">
                {premiumServicesDetails.technologyScouting.title}
              </h2>
              <p>{premiumServicesDetails.technologyScouting.description}</p>
              <p className="text-GTI-BLUE-default text-base font-semibold self-start">
                Timeline: 30 Days
              </p>
            </div>
            <div className="flex-grow"></div>
            <button
              className="text-white bg-GTI-BLUE-default w-full rounded-bl-2xl rounded-br-2xl p-2"
              onClick={() =>
                handleCreate({
                  title: premiumServicesDetails.technologyScouting.title,
                  description:
                    premiumServicesDetails.technologyScouting.description,
                  timeline: "30",
                  amount: 2500,
                })
              }
            >
              Fees $2,500
            </button>
          </div>
          <div className="flex flex-col items-center bg-white gap-2 rounded-2xl">
            <div className="flex flex-col justify-center items-center p-2 gap-2">
              <h2 className="text-GTI-BLUE-default text-xl font-semibold">
                {premiumServicesDetails.openInnovationChallenge.title}
              </h2>
              <p>
                {premiumServicesDetails.openInnovationChallenge.description}
              </p>
              <p className="text-GTI-BLUE-default text-base font-semibold self-start">
                Timeline: 3 month
              </p>
            </div>
            <div className="flex-grow"></div>
            {/* <button
              className="text-white bg-GTI-BLUE-default w-full rounded-bl-2xl rounded-br-2xl p-2"
              onClick={() =>
                handleCreate({
                  title: premiumServicesDetails.openInnovationChallenge.title,
                  description:
                    premiumServicesDetails.openInnovationChallenge.description,
                  timeline: "90",
                  amount: 10000,
                })
              }
            >
              Pay $10,000
            </button> */}
            <a
              href="mailto:info@globaltechinterface.com"
              className="text-white w-full bg-GTI-BLUE-default p-2 rounded-bl-2xl rounded-br-2xl text-center block"
            >
              Send Email
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center gap-10 lg:p-5 rounded-2xl w-full">
        <h1 className="text-GTI-BLUE-default text-xl lg:text-2xl font-semibold text-center">
          Additional value added services
        </h1>
        <div className="grid grid-rows-2 lg:grid-cols-2 gap-10 bg-blue-100 p-3 lg:p-10 rounded-2xl">
          <div className="flex flex-col justify-center items-center bg-white gap-2 rounded-2xl p-2">
            <div className="flex gap-5 justify-center items-center">
              <p className="bg-blue-100 p-2 rounded-xl">01</p>
              <p>
                {
                  premiumServicesDetails.additionalValueAddedServices1
                    .description
                }
              </p>
            </div>
            <div className="flex-grow"></div>
            <button
              className="text-white bg-GTI-BLUE-default rounded-2xl p-2 w-1/2"
              onClick={() =>
                handleCreate({
                  title:
                    premiumServicesDetails.additionalValueAddedServices1.title,
                  description:
                    premiumServicesDetails.additionalValueAddedServices1
                      .description,
                  amount: 3500,
                })
              }
            >
              Fees $3,500
            </button>
          </div>
          <div className="flex flex-col justify-center items-center bg-white gap-2 rounded-2xl p-2">
            <div className="flex gap-5 justify-center items-center">
              <p className="bg-blue-100 p-2 rounded-xl">02</p>
              <p>
                {
                  premiumServicesDetails.additionalValueAddedServices2
                    .description
                }
              </p>
            </div>
            <div className="flex-grow"></div>
            <button
              className="text-white bg-GTI-BLUE-default rounded-2xl p-2 w-1/2"
              onClick={() =>
                handleCreate({
                  title:
                    premiumServicesDetails.additionalValueAddedServices2.title,
                  description:
                    premiumServicesDetails.additionalValueAddedServices2
                      .description,
                  amount: 5000,
                })
              }
            >
              Fees $5,000
            </button>
          </div>
          <div className="flex flex-col justify-center items-center bg-white gap-2 rounded-2xl p-2">
            <div className="flex gap-5 justify-center items-center">
              <p className="bg-blue-100 p-2 rounded-xl">03</p>
              <p>
                {
                  premiumServicesDetails.additionalValueAddedServices3
                    .description
                }
              </p>
            </div>
            <div className="flex-grow"></div>
            {/* <button
              className="text-white bg-GTI-BLUE-default rounded-2xl p-2 w-1/2"
              onClick={() =>
                handleCreate({
                  title:
                    premiumServicesDetails.additionalValueAddedServices3.title,
                  description:
                    premiumServicesDetails.additionalValueAddedServices3
                      .description,
                  amount: 7000,
                })
              }
            >
              Pay $7,000
            </button> */}
            <a
              href="mailto:info@globaltechinterface.com"
              className="text-white w-1/2 bg-GTI-BLUE-default p-2 rounded-2xl text-center block"
            >
              Send Email
            </a>
          </div>
          <div className="flex flex-col justify-center items-center bg-white gap-2 rounded-2xl p-2">
            <div className="flex gap-5 justify-center items-center">
              <p className="bg-blue-100 p-2 rounded-xl">04</p>
              <p>
                {
                  premiumServicesDetails.additionalValueAddedServices4
                    .description
                }
              </p>
            </div>
            <div className="flex-grow"></div>
            <div className="flex justify-center items-center w-full">
              <a
                href="mailto:info@globaltechinterface.com"
                className="text-white w-3/4 bg-GTI-BLUE-default p-2 rounded-2xl text-center block"
              >
                Timeline and cost will be based on the scope of work
              </a>
            </div>
          </div>
        </div>
      </div>
      {successModal && (
        <SuccessModal
          state={state}
          message={message}
          show={successModal}
          toggle={handleSuccessModal}
        />
      )}
    </div>
  );
};

export default ScouterPremiumServices;

export const HOME = "/";
export const PROFILE = "/profile/personal";
export const ARTICLE = "/articles";
export const BLOGS = "/blogs";
export const PUBLICATIONS = "/publications";
export const NEWS = "/news";
export const EVENTS = "/events";
export const USERDETAILS = "/users/updateuserdetails";
export const COMPDETAILS = "/users/updateuserdetails";
export const USER_APPROVE = "/users/acceptuserprofiles";
export const USER_REJECT = "/users/rejectuserprofiles";
export const USER_LIST = "/users/getcompanyexistingusers";
export const USER_RESETPWD = "/users/resetpassword";
export const DISPLAYER_PREMIUM_SERVICES = "/displayer-services";
export const SCOUTER_PREMIUM_SERVICES = "/scouter-services";
